<template>
    <section>
        <div class="row" v-if="!data.showInviteForm">
            <div class="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 d-flex mb-3">
                <div class="table-box p-3">
                    <div class="">
                        <div class="d-flex">
                            <div class="flex-grow-1">
                                <h2 class="mb-1"> {{ translations?.leadership }} ({{ translations?.mandatory }})</h2>
                                <p>{{ data.statusStat }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="my-3">
                        <div class="row">
                            <div class="col-12">
                                <div class="border-bottom mb-3">
                                    <div class="table-responsive">
                                        <table class="table table-hover table-striped">
                                            <thead class="table-header">
                                            <tr>
                                                <th scope="col">{{ translations?.role }}</th>
                                                <th scope="col">{{ translations?.users }}</th>
                                                <th scope="col" v-if="projectStatus != 'Completed'">{{ translations?.actions }}</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(row, key) in data.projectRoles" :key="key">
                                                <td width="50%">
                                                    <div class="w-50">
                                                        <select class="form-select" aria-label="User-Role" :disabled="row.is_disable" v-model="row.id">
                                                            <option :value="''">{{ translations?.select_role }}Select Role</option>
                                                            <option :value="dropdownRole.id" v-for="(dropdownRole, rkey) in roles"
                                                                    :key="rkey" :disabled="checkIsItemDisabled(dropdownRole)">
                                                                {{ dropdownRole.name }}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="assigned-user me-2" v-for="(user, userKey) in getRoleUsers(row.id)" :key="userKey">
                                                        <span class="me-2" @click="resendInvitation(user)" v-if="user.status == 'Invited'
                                                            && invitationData.invitation_status != 'Invited'">
                                                            <font-awesome-icon :icon="['fas', `paper-plane`]"></font-awesome-icon>
                                                        </span>
                                                        {{ user.name }}
                                                        <span class="ms-2" v-show="helperData.permission.canDelete"
                                                            @click="removeUser(user)"
                                                              v-if="(user.status == 'Invited' && invitationData.invitation_status != 'Invited') ||
                                                              (user.status == 'Re Invited' && invitationData.invitation_status != 'Invited')">
                                                            <font-awesome-icon :icon="['fas', `times-circle`]"></font-awesome-icon>
                                                        </span>
                                                    </div>
                                                </td>
                                                <td v-if="projectStatus != 'Completed'">
                                                    <button class="btn btn-primary rounded-pill" @click="showInviteForm(row)"
                                                        :disabled="invitationData.invitation_status === 'Invited' ? true : false">{{ translations?.assign }} </button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div>
                                    <button v-if="helperData.permission.canCreate && projectStatus != 'Completed'" class="btn btn-primary" type="button" @click.prevent="addMore"
                                       :disabled="invitationData.invitation_status === 'Invited' ? true : false"
                                        >+ {{ translations?.more }} </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <invite-user v-if="data.showInviteForm"
            :set-url="data.inviteUserUrl"
            :params="data.inviteUserParams"
            :type="data.type"
            :company-id="data.companyId"
            :role="data.roleId"
            :role-name="data.roleName"
            :id="data.project_id"
            @approverGroupSuccess="approverGroupSuccess"/>
    </section>
</template>

<script>
    import {useStore} from "vuex";
    import {useRoute} from 'vue-router';
    import {onMounted, reactive, computed, inject} from "vue";
    import InviteUser from "@/common/components/invite-user/Index";
    import HelperFunction from "@/common/helpers";

    export default {
        name: "ProjectUser",
        components: { InviteUser },
        setup() {
            const store = useStore();
            const route = useRoute();
            const { getAllData, storeFormData, permissionResolver, data: helperData } = HelperFunction();
            const company = store.getters.getSelectedBoard;
            const projectId = route.params.id;
            const toast = inject('toast')
            const invitationData = computed(() => store.getters.getProjectInvitationData);
            const projectStatus = computed(() => store.getters.getProjectStatus);

            const data = reactive({
                projectRoles: [],
                projectUsers: [],
                dropdownData: [],
                statusStat: "",
                project_id: projectId,
                showInviteForm: false,
                inviteUserUrl: '',
                companyId: company.id,
                invitationType: '',
                type: 'author',
                roleId: '',
                roleName: '',
            });

            let setParams = {
                company_id: company.id,
                project_id: data.project_id,
                check: null
            };
            data.setUrl = vueConfig.Project.ProjectUsers;

            onMounted(async () => {
                getData();
            });

            const translations = computed(() => {
                return store.state.translations;  
            });

            /** watch **/
            permissionResolver('project-user', true);
            /** watch end **/

            async function getData() {
                await getAllData(data.setUrl, setParams)
                    .then((response) => {
                        data.projectRoles = response.data.data.roles;                        
                        // inject is_edit property in projectRoles
                        for (let i in data.projectRoles) {
                            data.projectRoles[i].is_disable = true;
                        }

                        data.projectUsers = response.data.data.users;
                        let totalUser = data.projectUsers.length;
                        let totalAccepted = 0;
                        let totalDeclined = 0;
                        let totalWaiting = 0;

                        data.projectUsers.forEach(row => {
                            if (row.status == "Accepted") totalAccepted++;
                            else if (row.status == "Declined") totalDeclined++;
                            else if (row.status == "Invited") totalWaiting++;
                            else if (row.status == "Re Invited") totalWaiting++;
                        });

                        data.statusStat = totalAccepted + "/" + totalUser + " Accepted, " + totalDeclined + " Declined, " + totalWaiting + " waiting";
                    })
            }

            function getRoleUsers(roleId) {
                return data.projectUsers.filter(function(user) {
                    return user.role_id == roleId;
                });
            }

            const roles = computed(() => {
                let roles = store.getters.getProjectRoles;
                const getRoles = roles.filter(function(item) {
                    if (item.is_project_group == 1) return item;
                });
                return getRoles;
            });

            function checkIsItemDisabled(list) {
                for (let i in data.projectRoles) {
                    if (list.id == data.projectRoles[i].id) {
                        return true;
                    }
                }
                return false;
            }

            function addMore() {
                let obj = {
                    id: "",
                    name: "",
                    is_disable: false,
                    project_id: route.params.id
                };
                data.projectRoles.push(obj);
            }

            // Invite component control
            function showInviteForm(roleInfo) {
                if (!roleInfo) {
                    toast.warning('Please select role first')
                    return;
                }
                data.roleId = roleInfo.id;
                data.roleName = roleInfo.name;
                data.inviteUserUrl = `${vueConfig.Project.ProjectApprovers}?project_id=${data.project_id}&role_id=${data.roleId}&company_id=${company.id}`;
                data.showInviteForm = true;
            }

            const approverGroupSuccess = async (value) => {
                if (value != "cancel") {
                    // Build payload from form value
                    let payload = {
                        company_id: value.company_id,
                        role_id: value.role_id,
                        type: value.type,
                        data: value.data,
                        _method: "PUT"
                    };
                    // console.error(payload, 'FORMDATA'); return
                    await storeFormData({url: `${vueConfig.Project.ProjectApprovers}/${data.project_id}`}, payload)
                        .then(() => {
                            getData()
                        })
                }
                data.showInviteForm = false;
            }
            async function removeUser(user) {
                //console.error(user)
                const data = {
                    status: user.status,
                    id: user.agmi_id ? user.agmi_id : user.agm_id,
                    from: user.from,
                    company_id: company.id
                };
                await storeFormData({url: vueConfig.Project.Setup.removeProjectUserEndPoint }, data)
                    .then(() => {
                        getData()
                    })
            }
            async function resendInvitation(user) {
                //console.error(user)
                const data = {
                    status: user.status,
                    id: user.agmi_id ? user.agmi_id : user.agm_id,
                    from: user.from,
                    company_id: company.id
                };
                await storeFormData({url: vueConfig.Project.Setup.resendInvitationEndPoint }, data)
                    .then(() => {
                        getData()
                    })
            }

            return {
                data, addMore, getRoleUsers, roles, checkIsItemDisabled, invitationData,
                showInviteForm, approverGroupSuccess, removeUser, resendInvitation, helperData, projectStatus, translations
            };
        },
    };
</script>

<style scoped>
    .table tbody tr td a {
        color: #fff;
    }
    .table tbody tr td .assigned-user span{
        cursor: pointer;
    }
    .table tbody tr td .assigned-user span svg{
        color: black;
    }
</style>
